export const defaultConfig = {
  nonEditableInput: false,
  min: 1,
  max: 999,
  defaultValue: 1,
};

export default class InputStepper {
  constructor(element, config) {
    this.element = element;
    this.config = { ...defaultConfig, ...config };
    this.input = null;
    this.numberDisplay = null;
    this.add = null;
    this.remove = null;
    this.element.ODS_InputStepper = this;
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleInputClick = this.handleInputClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleButtonState = this.handleButtonState.bind(this);

    this.init();
  }

  handleInputClick() {
    this.input && this.input.select();
  }

  handleButtonState() {
    let { min, max } = this.config;
    const value = !this.config.nonEditableInput
      ? parseInt(this.input.value, 10)
      : parseInt(this.numberDisplay.textContent, 10);

    if (this.add && typeof max === 'number' && value >= max) {
      this.add.setAttribute('aria-disabled', true);
      this.add.setAttribute('disabled', true);
    } else {
      this.add.removeAttribute('aria-disabled');
      this.add.removeAttribute('disabled');
    }
    if (this.remove && typeof min === 'number' && value <= min) {
      this.remove.setAttribute('aria-disabled', true);
      this.remove.setAttribute('disabled', true);
    } else {
      this.remove.removeAttribute('aria-disabled');
      this.remove.removeAttribute('disabled');
    }
  }

  handleInputChange(e) {
    let { min, max } = this.config;

    if (e) {
      if (min && e.target.value <= min) {
        e.target.value = min;
      }
      if (max && e.target.value >= max) {
        e.target.value = max;
      }
    }
  }

  handleButtonClick(e) {
    let { min, max } = this.config;
    let value = !this.config.nonEditableInput
      ? parseInt(this.input.value, 10)
      : parseInt(this.numberDisplay.textContent, 10);

    if (e.target.classList.contains('input-stepper__button--minus')) {
      if (min && value <= min) {
        if (e.target.getAttribute('disabled') === 'true') return;
        e.target.setAttribute('aria-disabled', true);
        e.target.setAttribute('disabled', true);
        return;
      }
      value -= 1;
    }
    if (e.target.classList.contains('input-stepper__button--plus')) {
      if (max && value >= max) {
        if (e.target.getAttribute('disabled') === 'true') return;
        e.target.setAttribute('aria-disabled', true);
        e.target.setAttribute('disabled', true);
        return;
      }
      value += 1;
    }
    if (!this.config.nonEditableInput) {
      this.input.value = value;
    } else {
      this.numberDisplay.textContent = value;
    }
    this.handleButtonState();
  }

  init() {
    this.config = {
      ...this.config,
      ...JSON.parse(this.element.getAttribute('data-inputstepper')),
    };

    if (!this.config.nonEditableInput) {
      this.input = this.element.querySelector('.input-stepper__field');
      this.input.value = this.defaultValue;
    } else {
      this.numberDisplay = this.element.querySelector('.input-stepper__number');
      this.numberDisplay.textContent = this.defaultValue;
    }
    this.add = this.element.querySelector('.input-stepper__button--plus');
    this.remove = this.element.querySelector('.input-stepper__button--minus');

    let { min, max, defaultValue } = this.config;

    if (defaultValue) {
      if (!this.config.nonEditableInput) {
        this.input.value = defaultValue;
      } else {
        this.numberDisplay.textContent = defaultValue;
      }
    }
    if (min !== undefined) {
      min = parseInt(min, 10);
    }
    if (max !== undefined) {
      max = parseInt(max, 10);
    }

    if (!this.element.classList.contains('input-stepper--disabled')) {
      this.add && this.add.addEventListener('click', this.handleButtonClick);
      this.remove &&
        this.remove.addEventListener('click', this.handleButtonClick);
      if (!this.config.nonEditableInput) {
        this.input &&
          this.input.addEventListener('click', this.handleInputClick);
        this.input &&
          this.input.addEventListener('change', this.handleInputChange);
      }
      this.handleButtonState();
    }
  }

  update() {
    this.destroy();
    this.init();
  }

  destroy() {
    if (!this.config.nonEditableInput) {
      this.input &&
        this.input.removeEventListener('click', this.handleInputClick);
      this.input &&
        this.input.removeEventListener('change', this.handleInputChange);
    }
    this.add && this.add.removeEventListener('click', this.handleButtonClick);
    this.remove &&
      this.remove.removeEventListener('click', this.handleButtonClick);
  }

  static getInstance(el) {
    return el && el.ODS_InputStepper ? el.ODS_InputStepper : null;
  }
}
