import { createPopper } from '@popperjs/core';
import { transitionHiddenElement } from '@cloudfour/transition-hidden-element';

import { keys } from '../../utils';

const defaultConfig = {
  rootContainerSelector: '#root-tooltips',
  removeDialogOnDestroy: false,
  popperOptions: {
    placement: 'bottom',
    strategy: 'absolute',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: '.tooltip__arrow',
          padding: 10,
        },
      },
      {
        name: 'eventListeners',
        options: {
          scroll: false,
          resize: false,
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['right', 'top', 'auto'],
        },
      },
      {
        name: 'offset',
        options: {
          offset: ({ placement }) => {
            if (placement.includes('start')) {
              return [-10, 0];
            } else if (placement.includes('end')) {
              return [10, 0];
            }

            return [0, 0];
          },
        },
      },
    ],
  },
};

export default class Tooltip {
  constructor(element, config) {
    this.element = element;
    this.config = { ...defaultConfig, ...config };
    this.config.popperOptions = {
      ...defaultConfig.popperOptions,
      ...(config?.popperOptions || []),
    };
    this.config.popperOptions.modifiers = [
      ...defaultConfig.popperOptions.modifiers,
      ...(config?.popperOptions?.modifiers || []),
    ];

    this.handleVisibility = this.handleVisibility.bind(this);
    this.onKeydown = this.onKeydown.bind(this);

    this.element.ODS_Tooltip = this;

    this.init();

    return this;
  }

  static getInstance(el) {
    return el && el.ODS_Tooltip ? el.ODS_Tooltip : null;
  }

  wrapLastWordAndButton() {
    // Check if the element has the 'data-tooltip-keep-with-text' attribute
    if (!this.element.hasAttribute('data-tooltip-keep-with-text')) {
      return;
    }

    // Get the previous sibling of the trigger
    const previousSibling = this.trigger.previousSibling;

    if (!previousSibling || previousSibling.nodeType !== Node.TEXT_NODE) {
      console.warn('Previous sibling is not a text node.');
      return;
    }

    // Get the text content of the previous sibling
    const textContent = previousSibling.textContent.trim();

    // Split the text content into words
    const words = textContent.split(' ');

    // Get the last word
    const lastWord = words.pop();

    // Update the text content without the last word
    const updatedTextContent = words.join(' ');

    // Update the text content of the previous sibling with a trailing space
    previousSibling.textContent = updatedTextContent + ' ';

    // Create a new span element to wrap the last word and button
    const span = document.createElement('span');
    span.className = 'text-nowrap';

    // Set the innerHTML of the span to include the last word and the button
    span.innerHTML = `${lastWord} ${this.trigger.outerHTML}`;

    // Replace the original button with the span
    this.trigger.parentNode.replaceChild(span, this.trigger);

    // Update the trigger reference to the new button inside the span
    this.trigger = span.querySelector('[data-tooltip-trigger]');
  }

  init() {
    this.trigger = document.querySelector(
      `[data-tooltip-trigger][aria-describedby=${this.element.getAttribute(
        'id'
      )}]`
    );

    if (!this.trigger) {
      // eslint-disable-next-line no-console
      console.warn(
        `\`tooltip trigger\` element is not defined and the tooltip cannot be attached. Please provide an element with [aria-describedby=${this.element.getAttribute(
          'id'
        )}]`
      );
    }

    if (this.element.hasAttribute('data-tooltip-keep-with-text')) {
      this.wrapLastWordAndButton();
    }

    this.rootContainer = document.querySelector(
      this.config.rootContainerSelector
    );

    if (this.rootContainer) {
      this.rootContainer.appendChild(this.element);
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        `\`rootContainer\` element is not defined. Tooltips will be placed inside content which can affect positioning. Please provide \`rootContainer\` element (should be placed outside of main contant, usualy in end of <body /> tag)`
      );
    }

    const customPlacement = this.element.getAttribute('data-tooltip-placement');
    if (customPlacement) {
      this.config.popperOptions.placement = customPlacement;
    }

    this.instance = createPopper(
      this.trigger,
      this.element,
      this.config.popperOptions
    );

    this.transitioner = transitionHiddenElement({
      element: this.element,
      visibleClass: 'is-visible',
    });

    this.trigger.addEventListener('mouseenter', this.handleVisibility);
    this.trigger.addEventListener('mouseleave', this.handleVisibility);
    this.trigger.addEventListener('focus', this.handleVisibility);
    this.trigger.addEventListener('blur', this.handleVisibility);
  }

  handleVisibility(e) {
    if (e.type === 'mouseenter' || e.type === 'focus') {
      this.show();
    }

    if (e.type === 'blur') {
      this.hide();
    }

    if (e.type === 'mouseleave') {
      const shouldClose = this.shouldClose(e);

      if (shouldClose) {
        this.hide();
      }
    }
  }

  shouldClose(e) {
    const mouseLeftTo = e.relatedreference || e.toElement || e.relatedTarget;

    const callback = e2 => {
      const mouseLeftTo2 =
        e2.relatedreference || e2.toElement || e2.relatedTarget;

      this.element.removeEventListener('mouseleave', callback);

      if (!this.trigger.contains(mouseLeftTo2)) {
        this.hide();
      }
    };

    if (this.element.contains(mouseLeftTo)) {
      this.element.addEventListener('mouseleave', callback);
      return false;
    }

    return true;
  }

  onKeydown(e) {
    if (keys.ESC.includes(e.key)) {
      this.hide();
    }
  }

  show() {
    this.transitioner.show();
    this.trigger.classList.add('is-active');
    this.instance.forceUpdate();
    document.addEventListener('keydown', this.onKeydown);
  }

  hide() {
    this.transitioner.hide();
    this.trigger.classList.remove('is-active');
    document.removeEventListener('keydown', this.onKeydown);
  }

  update() {
    this.instance.forceUpdate();
  }

  destroy() {
    this.trigger.removeEventListener('mouseenter', this.handleVisibility);
    this.trigger.removeEventListener('mouseleave', this.handleVisibility);
    this.trigger.removeEventListener('focus', this.handleVisibility);
    this.trigger.removeEventListener('blur', this.handleVisibility);

    this.instance.destroy();
    this.element.ODS_Tooltip = null;

    if (this.config.removeDialogOnDestroy) {
      this.element.remove();
    }
  }
}
